import React, { Fragment } from 'react';
import Head from 'next/head';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LayoutVertical from '@src/components/LayoutVertical';
import { useRouter } from 'next/router';
import { ROUTES } from '@src/types/routes';
import useWindowUtils from '@src/hooks/useWindowUtils';
import ButtonSwiper from '@src/components/ButtonSwiper';

export default function Landing() {
  const router = useRouter();
  const { isPlatformiOS } = useWindowUtils();

  const getStarted = () => {
    router.push({
      pathname: ROUTES.WELCOME
    });
  };

  return (
    <Fragment>
      <LayoutVertical>
        <Head>
          <title>2degrees Real Mode</title>
        </Head>
        <Row className="mb-4">
          <Col className="text-white" data-testid="introCopy">
            <p>
              All over New Zealand Kiwis are switching to Real Mode. Why
              don&apos;t you have a little taste?
            </p>
            <p>
              What better time to switch off than when you sit down to eat? A
              meal used to be such a great place to connect with each other, now
              it can be a scrollfest. Let&apos;s reclaim it.
            </p>
            <p>
              Switch to Real Mode when you sit down to a meal and spend time
              together without distraction. You&apos;ll go in the draw to win
              $100 supermarket voucher too. Win. Win. Go on, get amongst it!
            </p>
            <p>Go on, get amongst it!</p>
          </Col>
        </Row>
        <Row className="d-flex mt-auto">
          <Col className="text-white col mb-6">
            <ButtonSwiper
              variant="light"
              completeCallback={getStarted}
              testId="getStartedButton"
            >
              Swipe to get started
            </ButtonSwiper>
          </Col>
        </Row>
      </LayoutVertical>

      {isPlatformiOS === true && (
        <div
          className="vw-100 text-primary p-4 bg-white"
          data-testid="installSteps"
        >
          <h1 className="mb-4">How to install (optional)</h1>
          <div className="how-to-install-note">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 16.1953C12.2891 16.1953 15.8359 12.6484 15.8359 8.35938C15.8359 4.0625 12.2891 0.523438 7.99219 0.523438C3.70312 0.523438 0.164062 4.0625 0.164062 8.35938C0.164062 12.6484 3.71094 16.1953 8 16.1953ZM8 15.1797C4.21875 15.1797 1.17969 12.1328 1.17969 8.35938C1.17969 4.57812 4.21875 1.53125 7.99219 1.53125C11.7734 1.53125 14.8203 4.57812 14.8281 8.35938C14.8281 12.1328 11.7812 15.1797 8 15.1797ZM7.95312 5.40625C8.4375 5.40625 8.8125 5.03125 8.8125 4.55469C8.8125 4.07812 8.4375 3.6875 7.95312 3.6875C7.47656 3.6875 7.09375 4.07812 7.09375 4.55469C7.09375 5.03125 7.47656 5.40625 7.95312 5.40625ZM6.60156 12.6172H9.80469C10.0547 12.6172 10.25 12.4297 10.25 12.1797C10.25 11.9453 10.0547 11.75 9.80469 11.75H8.67969V7.36719C8.67969 7.05469 8.51562 6.83594 8.21094 6.83594H6.70312C6.45312 6.83594 6.25781 7.03125 6.25781 7.26562C6.25781 7.51562 6.45312 7.70312 6.70312 7.70312H7.72656V11.75H6.60156C6.35156 11.75 6.15625 11.9453 6.15625 12.1797C6.15625 12.4297 6.35156 12.6172 6.60156 12.6172Z"
                fill="#1D292E"
              />
            </svg>
            <p className="text-dark mb-2">
              If you have another browser set to your default please open the
              link in Safari and follow the below instructions to install to
              your home screen
            </p>
          </div>
          <p className="text-dark mb-2">
            Real Mode is a web app, which works just like a regular app but you
            install it like this:
          </p>
          <p className="text-dark mb-2">
            1. Tap the &lsquo;Share&rsquo; button, this could be at the top or
            bottom of the screen. It looks a bit like this:
          </p>
          <div className="d-flex align-items-center justify-content-center mt-3 mb-7">
            <div className="ios-share-button">
              <svg
                width="42"
                height="53"
                viewBox="0 0 42 53"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.35938 52.8984H34.2422C36.6953 52.8984 38.5312 52.2891 39.75 51.0703C40.9844 49.8516 41.6016 48.0391 41.6016 45.6328V22.2422C41.6016 19.8359 40.9844 18.0234 39.75 16.8047C38.5312 15.5859 36.6953 14.9766 34.2422 14.9766H27.7031V18.75H34.1719C35.3281 18.75 36.2266 19.0625 36.8672 19.6875C37.5078 20.3125 37.8281 21.2344 37.8281 22.4531V45.4219C37.8281 46.6406 37.5078 47.5625 36.8672 48.1875C36.2266 48.8125 35.3281 49.125 34.1719 49.125H7.40625C6.23438 49.125 5.33594 48.8125 4.71094 48.1875C4.08594 47.5625 3.77344 46.6406 3.77344 45.4219V22.4531C3.77344 21.2344 4.08594 20.3125 4.71094 19.6875C5.33594 19.0625 6.23438 18.75 7.40625 18.75H13.8984V14.9766H7.35938C4.90625 14.9766 3.0625 15.5859 1.82812 16.8047C0.609375 18.0078 0 19.8203 0 22.2422V45.6328C0 48.0547 0.609375 49.8672 1.82812 51.0703C3.0625 52.2891 4.90625 52.8984 7.35938 52.8984ZM20.7891 34.5469C21.3047 34.5469 21.7422 34.3672 22.1016 34.0078C22.4766 33.6484 22.6641 33.2188 22.6641 32.7188V8.64844L22.5234 5.13281L24.0938 6.79688L27.6562 10.5938C27.9844 10.9688 28.4062 11.1562 28.9219 11.1562C29.4062 11.1562 29.8125 11 30.1406 10.6875C30.4688 10.375 30.6328 9.97656 30.6328 9.49219C30.6328 9.05469 30.4453 8.64844 30.0703 8.27344L22.1484 0.632812C21.9141 0.398438 21.6875 0.234375 21.4688 0.140625C21.2656 0.046875 21.0391 0 20.7891 0C20.5547 0 20.3281 0.046875 20.1094 0.140625C19.9062 0.234375 19.6797 0.398438 19.4297 0.632812L11.5078 8.27344C11.1484 8.64844 10.9688 9.05469 10.9688 9.49219C10.9688 9.97656 11.125 10.375 11.4375 10.6875C11.75 11 12.1562 11.1562 12.6562 11.1562C13.1875 11.1562 13.6172 10.9688 13.9453 10.5938L17.4844 6.79688L19.0781 5.13281L18.9375 8.64844V32.7188C18.9375 33.2188 19.1172 33.6484 19.4766 34.0078C19.8516 34.3672 20.2891 34.5469 20.7891 34.5469Z"
                  fill="#009ED8"
                />
              </svg>
            </div>
          </div>

          <p className="text-dark mb-2">
            2. Scroll down and tap &lsquo;Add to Home Screen.&rsquo;
          </p>
          <img
            src="/images/A2HS-step-2.png"
            alt="Add to home screen step 2"
            width="100%"
            className="my-2"
          />
          <p className="text-dark my-2">3. Tap &lsquo;Add&rsquo;</p>
          <img
            src="/images/A2HS-step-3.png"
            alt="Add to home screen step 3"
            width="100%"
            className="my-2"
          />
          <p className="text-dark my-2">4. Done. Easy as.</p>
        </div>
      )}
    </Fragment>
  );
}
